<template>
	<Modal classes="modal--invite" class="modal--invite-content">
		<template #header>
			<VHeading level="2">Invite team members</VHeading>
		</template>
		<template #content>
			<VText size="small">
				Invite people to your organisation. They will receive an email to join.
				Once registered, they will be associated with your organisation. As
				admin, you can remove them at any time.
			</VText>
			<div class="content">
				<div class="content__inner">
					<div class="form__group">
						<VDefaultField :error="errors.get('email')">
							<template #field>
								<VInput
									v-model="form.email"
									type="email"
									placeholder="mike@email.com"
									:error="errors.get('email')"
									@input="errors.clear('email')"
								/>
							</template>
						</VDefaultField>
					</div>
					<div class="form__group">
						<VDefaultField :error="errors.get('isAdmin')">
							<template #field>
								<VCheckbox id="is-admin" v-model="form.isAdmin">
									Make admin (can edit organisation profile & invite others)
								</VCheckbox>
							</template>
						</VDefaultField>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<VButton color="primary" classes="save-btn" @click.stop="submit">
				Invite
			</VButton>
		</template>
	</Modal>
</template>

<script>
import Errors from '@/helpers/Errors';
import { INVITE_MEMBER, FETCH_MEMBERS } from '@/store/actions.type';
import { SET_ACTIVE_MODAL } from '@/store/mutations.type';

export default {
	name: 'InviteTeamMembersModal',
	data() {
		return {
			errors: new Errors(),
			form: {
				email: '',
				isAdmin: false
			}
		};
	},
	methods: {
		async submit() {
			try {
				await this.$store.dispatch(INVITE_MEMBER, this.form);
				this.$store.dispatch(FETCH_MEMBERS, this.form);
				this.$store.commit(SET_ACTIVE_MODAL, null);

				this.$toastr.s('Success', 'Successfully invite user');
			} catch (errors) {
				this.errors.record(errors.response.data.errors);
			}
		}
	}
};
</script>

<style scoped>
/deep/ .form__checkbox + .form__label {
	padding-left: 0;
}

/deep/ .form__checkbox:checked + .form__label:after {
	top: 3px;
}
</style>
